import StoriesSlider from "@/components/Ui/Shared/StoriesSlider/StoriesSlider";
import For from "@/components/Tools/For/For";
import Layout from "@/components/Tools/Layout/Layout";
import {
  IBannerData,
  IData,
} from "@/types/global.types";
import Head from "next/head";
import { GetStaticProps } from "next";
import { pages } from "@/services/requests/requests";
import { MEDIA_URL } from "@/constant";
import Image from "next/image";
import { useId } from "react";
import useMedia from 'use-media';

interface Props {
  data: IData<IBannerData[]>;
}

function Home({ data }: Props) {
  const mainLeftKey = useId();
  const mainSideKey = useId();
  const isMobile = useMedia({ maxWidth: '576px' });

  return (
    <Layout>
      <Head>
        <title>ModaBU | Ana səhifə</title>
      </Head>
      <StoriesSlider type={"landing"} />
      <div className="home">
        <div className="main-banner">
          <For each={data?.data}>
            {(item, index) =>
              item.position === 1 ? (
                <a key={mainLeftKey + index} href={item.url} className="banner">
                  {
                    !isMobile ? <Image
                      alt={item.title}
                      height={195}
                      src={MEDIA_URL + item.image}
                      width={744}
                    /> : <Image
                      alt={item.title}
                      height={195}
                      src={MEDIA_URL + item.mob_image}
                      width={744}
                    />
                  }
                </a>
              ) : (
                ""
              )
            }
          </For>
        </div>
        <div className="side-banner">
          <For each={data?.data}>
            {(item, index) =>
              item.position === 2 ? (
                <a key={mainSideKey + index} href={item.url} className="banner">
                  {
                    !isMobile ? <Image
                      alt={item.title}
                      height={360}
                      src={MEDIA_URL + item.image}
                      width={360}
                      unoptimized
                      fetchPriority="high"
                    /> : <Image
                      alt={item.title}
                      height={360}
                      src={MEDIA_URL + item.mob_image}
                      width={360}
                      unoptimized
                      fetchPriority="high"
                    />
                  }
                </a>
              ) : (
                ""
              )
            }
          </For>
        </div>
      </div>
    </Layout>
  );
}

export default Home;

export const getStaticProps: GetStaticProps = async () => {
  const data = await pages.getProductBanner({
    category: "landing",
  });

  try {
    return {
      props: {
        data,
      },
      revalidate: 60,
    };
  } catch (error) {
    return {
      props: {
        data: null,
        revalidate: 60,
      },
    };
  }
};
